
import { withRouter, Link, Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import Bundle from './Bundle.jsx'

let CourseShare = function () {
  return <Bundle load={() => import('./pages/Course/CourseShare')}>
    {(Com) => <Com />}
  </Bundle>;
};
let getcode = function () {
  return <Bundle load={() => import('./pages/Course/getcode')}>
    {(Com) => <Com />}
  </Bundle>;
};
let TakePrice = function () {
  return <Bundle load={() => import('./pages/Course/TakePrice')}>
    {(Com) => <Com />}
  </Bundle>;
};
let TxRecord = function () {
  return <Bundle load={() => import('./pages/Course/TxRecord')}>
    {(Com) => <Com />}
  </Bundle>;
};
let LoginCourse = function () {
  return <Bundle load={() => import('./pages/Course/LoginCourse')}>
    {(Com) => <Com />}
  </Bundle>;
};
let codeList = function () {
  return <Bundle load={() => import('./pages/Course/codeList')}>
    {(Com) => <Com />}
  </Bundle>;
};
let exchange = function () {
  return <Bundle load={() => import('./pages/Course/exchange')}>
    {(Com) => <Com />}
  </Bundle>;
};
let mtCodeList = function () {
  return <Bundle load={() => import('./pages/mt/mtCodeList')}>
    {(Com) => <Com />}
  </Bundle>;
};
function App(props) {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/CourseShare" component={CourseShare} />
          <Route path="/getcode" component={getcode} />
          <Route path="/TakePrice" component={TakePrice} />
          <Route path="/TxRecord" component={TxRecord} />
          <Route path="/LoginCourse" component={LoginCourse} />
          <Route path="/codeList" component={codeList} />
          <Route path="/exchange" component={exchange} />
          <Route path="/mtcodeList" component={mtCodeList} />
          {/* <Route path="/qfhpN5AkhM.txt" component={check}/> */}
        </Switch>
      </Router>
    </>
  );
}

export default App;